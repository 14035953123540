<template>
  <div v-if="showPagination">
    <div class="pager">
      <a v-if="isPrev" class="page" :class="{'inactive': isPrev}">Prev</a>
      <a v-else class="page" rel="prev" :href="newUrl(prevPage)" remote="true">Prev</a>
      <a v-for="page in pages" :key="page.name" rel="pages" :href="newUrl(page)" class="page" :class="{ active: isActive(page) }">
          {{ page }}
      </a>
      <a v-if="isNext" class="page" :class="{'inactive':isNext}">Next</a>
      <a v-else  class="page" rel="next" :href="newUrl(nextPage)" remote="true">Next</a>
    </div>
    <div class="info">Showing {{ propNumberFrom }} - {{ propNumberTo }} of {{ totalCount }} properties</div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      url: window.location.href.split("?")[0],
      pages: [],
      currentPage: this.$props.pagination.current_page,
      perPage: this.$props.pagination.per_page,
      propNumberFrom: this.$props.pagination.prop_number_from,
      propNumberTo: this.$props.pagination.prop_number_to,
      totalCount: this.$props.pagination.total_count,
      totalPages: this.$props.pagination.total_pages,
    };
  },
  props: {
    pagination: Object,
    init: String
  },
  methods: {
    newUrl(pageNumber) {
      if (pageNumber > 1) {
        return this.url + "?page=" + pageNumber;
      } else {
        return this.url;
      }

    },
    // cap the pagination 
    capPage() {
      if (this.totalPages > 399) {
        this.totalPages = 400
      }
    },
    createPager() {
      let current = this.currentPage,
          last = this.totalPages,
          delta = 2,
          left = current - delta,
          right = current + delta + 1;

      for (let i = 1; i <= last; i++) {
        if (i >= left && i < right) {
          this.pages.push(i);
        }
      }   
    },
    isActive(page) {
      return page === this.currentPage;
    },
    scrollUp() {
      const el = document.getElementById('seo')
      window.scrollTo({
          top: el.offsetTop,
          behavior: "smooth"
      });
    }
  },
  computed: {
    showPagination() {
      return this.totalPages > 1;
    },
    prevPage() {
      return this.currentPage - 1;
    },
    nextPage() {
      return this.currentPage + 1;
    },
    isPrev() {
      return this.currentPage === 1;
    },
    isNext() {
      return this.currentPage === this.totalPages;
    },
  },
  mounted() {
    this.capPage();
    this.createPager();
    if(this.$props.init === "false") {
      this.scrollUp()
    }
  }
};
</script>

<style scoped lang="scss">
@import "app/assets/stylesheets/_vars.scss";

.pager {
  padding-top:1.25rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.page {
  text-align: center;
  color: $col_orange;
  font-weight: 600;
  background: transparent;
  height: 100%;
  outline: 0;
  padding: .625rem;
  cursor: pointer;
  font-size: .875rem;
  text-decoration: none;

  &:first-of-type {
    width: 25%;
  }

  &:last-of-type {
    width: 25%;
  }

  &.active {
    color: $col_white;
    position: relative;
  
    &::before{
      content: "";
      position: absolute;
      border-radius: 100%;
      background: $col_orange;
      cursor: default;
      width: 1.5rem;
      height: 1.5rem;
      z-index: -1;
      top: 50%;
      left: 50%;
      margin: -.8rem 0 0 -.75rem;
    }
  }

  &.inactive {
    color: lighten($col_orange, 20%);
    cursor: default;
    
  }
}

.info {
  margin-top: 1rem;
  font-size: 0.9rem;
  text-align: center;
  font-size: 0.875rem;
  padding-bottom: 4rem;
}
</style>
