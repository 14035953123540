<template>
  <section>
    <div v-gallery class="slider" :class="{ 'hide': hideUI }">
      <div v-if="!card" class="frame">
        <div class="itm" v-for="(image, index) in images" :key="index" :style="{ backgroundImage: index === 0 ? 'url(' + image.file.hero.url + ')' : '' }">
          <img class="img" v-if="index === 0" :src="image.file.hero.url" :alt="image.caption" />
          <img class="img" v-if="index > 0" :data-src="image.file.hero.url" :alt="image.caption" />
          <div v-if="!card" class="caption">
            <p class="caption-text" v-html="image.caption"></p>
          </div>
        </div>
      </div>
      <div v-else class="frame">
        <div class="itm" v-for="(image, index) in images" :key="index" v-if="index < 5" :style="{ backgroundImage: index === 0 ? 'url(' + image.url + ')' : '' }">
          <img class="img" v-if="index === 0" :src="image.url" :alt="image.caption" />
          <img class="img" v-if="index > 0" :data-src="image.url" :alt="image.caption" />
        </div>
      </div>
      <div v-if="!card" v-show="!showGal" class="count">{{ currentImage | index }} / {{ totalPics }}</div>
      <div v-show="images.length > 1" class="prev" @click="goPrev">
        <svg class="ico">
          <use xlink:href="#ico-arr"></use>
        </svg>
      </div>
      <div v-show="images.length > 1" class="next" @click="goNext">
        <svg class="ico">
          <use xlink:href="#ico-arr"></use>
        </svg>
      </div>
      <button v-if="!card" class="viewbtn" @click.stop="openGal">View {{ totalPics }} photos</button>
    </div>

    <div v-if="!card" class="gal" :class="{ 'visible': showGal }" @click.stop="closeGal">
      <div class="index">{{ currentImage | index }} / {{ totalPics }}</div>
      <div class="close" @click.stop="closeGal">&#x2715;</div>
      <div id="gal"></div>
    </div>
  </section>
</template>

<script>

export default {
  data: function () {
    return {
      currentImage: 0,
      showGal: false,
      hideUI: false,
      timer: null,
      totalPics: this.images.length
    }
  },
  props: {
      images: {
          type: Array,
          required: true
      },
      card: Boolean
  },
  filters: {
      index: function (val) {
          return val + 1;
      }
  },
  methods: {
      goNext: function(e) {
          e.stopPropagation();
          e.currentTarget.parentNode.slide.next();
          this.$emit('track', "next_gallery_button_click");
      },
      goPrev: function(e) {
          e.stopPropagation();
          e.currentTarget.parentNode.slide.prev();
          this.$emit('track', "prev_gallery_button_click");
      },
      debounce (app, func, delay) {
          clearTimeout(app.timer);
          app.timer = setTimeout(function() {
              func();
          }, delay);
      },
      addKeyEvents: function(e) {
          let app = this,
              gal = this.$el.querySelector("#gal .slider");

          if (app.showGal && e.keyCode === 27) {
              app.showGal = false;
              app.closeGal();
          }
          if (app.showGal && e.keyCode === 39) {
              gal.slide.next();
          }
          if (app.showGal && e.keyCode === 37) {
              gal.slide.prev();
          }
      },
      getImages: function(el, images) {
          if (el.getAttribute("data-load") !== "1") {
              this.lazyload(el, images, 1);
              el.setAttribute("data-load", "1");
          }
      },
      addClass: function(img) {
          img.parentNode.classList.add(img.clientWidth > img.clientHeight ? "land" : "port");
      },
      lazyload: function(el, images, index) {
          let app = this;
          for (let i = index; i < images.length; i++) {
              let img = images[i];
              let datasrc = img.getAttribute("data-src");
              if (datasrc !== null) {
                  img.src = datasrc;
                  img.parentNode.style.backgroundImage = 'url(' + datasrc + ')';
              }
              img.onload = () => {
                  app.addClass(img);
              }
          }
      },
      createSlider: function(el) {
          let app = this;
          let images = el.querySelectorAll("img");
          let callback = function(index, el) {
              if (index !== 0) {
                  app.getImages(el, images);
                  app.currentImage = index;
                  app.hideUI = true;
                  app.$emit('hideBanners');
                  app.debounce(app, app.unhideUI, 1000);
              }
          };

          el.slide = new Swipe(el, {
              startSlide: 0,
              speed: 400,
              draggable: false,
              continuous: true,
              disableScroll: false,
              stopPropagation: false,
              callback: callback
          });
      },
      unhideUI: function() {
          this.hideUI = false;
          this.$emit('showBanners');
      },
      addPrevNext: function(el) {
        let app = this;
          el.querySelector(".prev").addEventListener("click", function(e) {
              app.goPrev(e);
          });
          el.querySelector(".next").addEventListener("click", function(e) {
              app.goNext(e);
          });
      },
      openGal: function() {
          this.$emit('track', "view_photos_button_click");
          let app = this;
          let clone = app.$el.querySelector(".slider"),
              gal = app.$el.querySelector("#gal"),
              el;

          el = clone.cloneNode(true);
          gal.appendChild(el);
          app.createSlider(app.$el.querySelector("#gal .slider"));
          app.$el.querySelector("#gal .slider").slide.slide(app.currentImage, 0);
          app.addPrevNext(app.$el.querySelector("#gal .slider"));
          app.showGal = true;
      },
      closeGal: function() {
          let slide = this.$el.querySelector(".slider"),
              galSlide = this.$el.querySelector("#gal .slider"),
              gal = this.$el.querySelector("#gal");

          slide.slide.slide(this.currentImage, 0);
          galSlide.slide.kill();
          gal.innerHTML = "";
          this.showGal = false;
      }
  },
  directives: {
      'gallery': {
          inserted: function(el, binding, vnode) {
              vnode.context.createSlider(el, binding, vnode);
          },
          unbind: function(el) {
              el.slide.kill();
          }
      }
  },
  mounted: function() {
      if (!this.card) {
          window.addEventListener('keydown', this.addKeyEvents);
      }
  },
  beforeDestroy: function() {
      if (!this.card) {
          window.removeEventListener('keydown', this.addKeyEvents);
      }
  }
}
</script>

<style scoped lang="scss">
@import 'app/assets/stylesheets/_vars.scss';

.slider {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.caption, .count {
  position: absolute;
  background: $col_opmidblack;
  padding: 0.4rem 1rem;
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem;
  box-sizing: border-box;
  left: 0;
}

.caption, .count, .viewbtn, .next, .prev, .gal {
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}

.caption-text, .count {
  color: $col_white;
  text-transform: uppercase;
  font-weight: 700;
  font-size: .75rem;
}

.caption {
  bottom: 1rem;
  left: 0;
  max-width: 50%;
}

.hide {
  .ban {
    opacity: 0;
  }
}

.count {
  top: 1.25rem;
  left: 1.25rem;
  border-radius: .25rem;
  background: rgba(255, 255, 255, 0.8);
  height: 1.7rem;
  padding: 0 0.625rem;
  text-align: center;
  line-height: 1.7rem;
  font-weight: 100;
  z-index: 3;
  color: $col_darkgrey;
  font-size: 0.75rem;
}

.viewbtn {
  bottom: 1rem;
  font-size: .75rem;
  font-weight: 700;
  text-transform: uppercase;
  height: 2.188rem;
  position: absolute;
  z-index: 9;
  padding: 0 1.25rem;
  right: 1rem;
  background: $col_white;
  border-radius: .25rem;
  box-sizing: border-box;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
  cursor: pointer;
  outline: none;

  &:focus {
    box-shadow: 0 0.125rem 1.25rem 0 $col_aqua;
  }
}

.frame {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.next,
.prev {
  position: absolute;
  cursor: pointer;
  top: 50%;
  z-index: 1;
  opacity: 0;
  user-select: none;
  margin-top: -1.25rem;

  .ico {
    fill: $col_white;
    width: 2.813rem;
    height: 2.813rem;
    filter: drop-shadow(0 0.125rem 0.125rem $col_quartopacity);
  }

  .slider:hover & {
    opacity: 1;
  }
}

.next {
  right: 1.25rem;
}

.prev {
  left: 1.25rem;

  .ico {
    transform: rotate(180deg);
  }
}

.itm {
  display: flex;
  flex-direction: row;
  align-items: center;
  float: left;
  width: 100%;
  position: relative;
  filter: saturate(120%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  will-change: transform;
  backface-visibility: hidden;
  min-height: 14.313rem;
}

.img {
  opacity: 0;
  width: 100%;

  .port & {
    height: 100%;
    width: auto;
  }
}

// GALLERY

.gal {
  position: fixed;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  opacity: 0;
  visibility: hidden;

  &:before {
    content: "";
    background-color: rgba(0, 0, 0, 0.88);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
  }

  &.visible {
    visibility: visible;
    opacity: 1;
    z-index: 1200;
    left: 0;
  }

  .viewbtn,
  .count {
    display: none;
  }
}

.index {
  position: absolute;
  left: 50%;
  margin-left: -1.25rem;
  color: $col_white;
  font-size: 0.875rem;
  top: 0.938rem;
}

.close {
  right: 0;
  top: 0;
  position: absolute;
  padding: .625rem;
  font-size: 1.25rem;
  color: $col_white;
  cursor: pointer;
  z-index: 3;
}

.view {
  overflow: hidden;
  position: relative;
  list-style: none;
  height: 100%;
}

.gal {
  .slider {
    box-sizing: border-box;
    width: 95%;
    margin: 0 auto;
    top: 2.5rem;
    position: relative;
    overflow: hidden;
  }

  .next,
  .prev {
    position: absolute;
    top: 50%;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    user-select: none;
    margin-top: -1.25rem;

    .ico {
      fill: $col_white;
      width: 2.813rem;
      height: 2.813rem;
      filter: drop-shadow(0 0.125rem 0.125rem $col_quartopacity);
    }
  }

  .index {
    position: absolute;
    background: rgba(255,255,255,0.8);
    height: 1.7rem;
    padding: 0 0.625rem;
    text-align: center;
    line-height: 1.7rem;
    border-radius: 0 0 .25rem .25rem;
    top: 0;
    left: calc(50% - 1rem);
    z-index: 3;
    color: $col_darkgrey;
    font-size: 0.75rem;
  }
}

.btn {
  bottom: 1.25rem;
  font-size: .75rem;
  font-weight: 700;
  text-transform: uppercase;
  height: 2.188rem;
  position: absolute;
  z-index: 9;
  padding: 0 1.25rem;
  right: 1.25rem;
  background: $col_white;
  border-radius: .25rem;
  box-shadow: 0 .125rem .25rem $col_quartopacity;
}

@media #{$xl-phone} {
  .gal .itm {
    height: 34.375rem;
  }

  .close {
    position: absolute;
  }
}

@media #{$desktop} {
  .gal .slider {
    max-width: 42.75rem;
  }

  .caption {
    padding: 0.4rem 4rem 0.4rem 2rem;

    &-text {
      font-size: 0.875rem;
    }
  }

  .contain {
    height: 100%;
    max-height: 35.063rem;
    max-width: 42.75rem;
  }

  .index {
    top: 1.25rem;
    left: 1.25rem;
    border-radius: .25rem;
  }
}

@media #{$desktop_lg} {
  .slider {
    max-width: initial;
  }
}

</style>