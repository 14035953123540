<template>
  <li :class="clName">
    <div class="card" :class="{ 'drv': search.region === '' && search.travel_origin_postcode !== '' }" :id="'search_' + search.search_id" @click="goToSearch(search.search_id, search)">
      <div class="slider">
        <div class="bans">
          <h3 class="shead" :class="{ 'long': search.region ? search.region.length > 10 : false }">
            <span v-if="search.region">{{ search.region | regionComma}}</span>
            <span v-if="!search.region && search.travel_origin_postcode">{{ search.travel_max_time | hours }} from {{ search.travel_origin_postcode }}</span>
            <span v-if="!search.region && !search.travel_origin_postcode">Draw a search</span>
          </h3>
          <h4 class="ssub">
            {{ search.check_in_on | formatDate() }} -
            {{ search.check_in_on | formatDate(search.nights) }}
            / {{ search.nights }} night<span v-if="search.nights > 1">s</span>
          </h4>
        </div>
        <svg class="ico">
          <use xlink:href="#icon-arr-right"/>
        </svg>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    search: {
      type: Object,
      required: true
    },
    clName: String
  },
  filters: {
    formatDate: function (date, nights) {
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

      let checkDate = new Date(date);

      if (nights) {
        checkDate.setDate(checkDate.getDate() + parseInt(nights));
      }

      return `${checkDate.getDate()} ${months[checkDate.getMonth()]}`;
    },
    hours: function(time) {
      if (time >= 60) {
        return time / 60 + " hrs";
      } else {
        return time + " mins";
      }

    },
    regionComma: function(str) {
      return str.split(',')[0].replace(/-/g, ' ');
    }
  },
  methods: {
    goToSearch: function (id, filter) {
      this.$emit('track', "history_search_card_click", id);
      window.location.href = id ? "/searches/" + id : "/top-deals";
    }
  }
}
</script>

<style scoped lang="scss">
@import 'app/assets/stylesheets/_vars.scss';

li {
  width: 100%;
  box-sizing: border-box;

  &:nth-child(3n+3) {
    margin: 0;
  }
}

.card {
  cursor: pointer;

  &:hover {
    box-shadow: 0 .125rem .875rem $col_aqua;
  }
}

.frame {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.slider {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: $col_offwhite;
  overflow: hidden;
  position: relative;
  min-height: 6rem;
  width: 100%;
  padding: 1rem 1.25rem;
  border-radius: 0.19rem;
  box-sizing: border-box;
  box-shadow: 0 .125rem .25rem #ccc;

  .ico {
    height: 2rem;
    width: 1rem;
    fill: $col_primary;
  }
}

.bans {
  width: 80%;
}

.ssub, .shead span {
  color: $col_black;
  width: 100%;
}

.shead {
  font-size: 1.25rem;
  margin-bottom: 0.875rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  .long {
    span {
      font-size: 1rem;
    }
  }
}

.ssub {
  font-size: .875rem;
  font-weight: 400;
}

</style>