<template>
  <div class="pager">
    <a rel="prev" :href="prevUrl()" remote="true" :class="{ 'hide': page === 1 }" >Prev</a>
    <a :class="{'active': $props.page === parseInt(pages) }" :href="url + '?page=' + pages" v-for="pages in pageArray">{{ pages }}</a>
    <a rel="next" :href="nextUrl()" remote="true" :class="{ 'hide': page === pageCount }" >Next</a>
  </div>
</template>

<script>
    // this is an a-link pager
    export default {
        data: function () {
            return {
                pageArray: [],
                url: window.location.href.split("?")[0]
            }
        },
        props: {
            pageCount: Number,
            page: Number
        },
        watch: {
            "pageCount": function(oldV, newV) {
                this.calcPages();
            }
        },
        methods: {
            prevUrl() {
                let prev = this.$props.page - 1;
                if (this.$props.page !== 1) {
                    return this.url + "?page=" + prev;
                }
            },

            nextUrl() {
                let next = this.$props.page + 1;
                if (this.$props.page !== this.$props.pageCount) {
                    return this.url + "?page=" + next;
                }
            },

            calcPages() {
                const app = this,
                      potential = document.body.clientWidth < 800 ? 7 : 15;
                let start = 1,
                    range = app.$props.pageCount <= potential ? app.$props.pageCount : potential,
                    pages = app.$props.pageCount,
                    current = app.$props.page,
                    paging = [],
                    end;

                let i = Math.min(pages + start - range, Math.max(start, current - (range / 2 | 0)));
                end = i + range;
                while (i < end) {
                    paging.push(`${i++}`)
                }
                app.pageArray = paging;
            }
        }
    }
</script>

<style scoped lang="scss">
@import 'app/assets/stylesheets/_vars.scss';

.pager {
    margin: 1.25rem 0 1.25rem;
    display: flex;
}

a {
  display: inline-block;
  min-width: 1.2rem;
  text-align: center;
  padding: 0.25rem;
  height: auto;
  font-size: .875rem;
  border-radius: 0.2rem;
  margin-right: .313rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,0.15);
  background: #FAFAFA;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  outline: 0;
  box-sizing: border-box;

  &:hover,
  &.active:hover {
    background: lighten($col_orange, 10%);
  }

  &:hover,
  &.active {
    color: $col_white;
    font-weight: 700;
  }

  &.active {
    background: $col_orange;
  }

  &.hide {
    display: none;
  }
}

@media #{$desktop} {
    .pager {
      margin: 0 0 1.25rem;
      justify-content: flex-end;
    }
}

</style>