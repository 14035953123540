<template>
  <div>
    <ul class="list" itemtype="http://schema.org/BreadcrumbList">
      <li itemscope="" itemprop="itemListElement" itemtype="http://schema.org/ListItem">
        <p @click="newSearch" :itemname="$props.location.name" class="org" itemprop="item">
          <svg>
            <use xlink:href="#ico-arr"></use>
          </svg>
          Search {{ $props.location.loc }}
        </p>
      </li>
      <li v-for="(crumb, index) in $props.crumbs" itemscope="" itemprop="itemListElement" itemtype="http://schema.org/ListItem">
        <a class="pur" :href="crumb.url" :itemname="crumbUrl(crumb.url)" itemprop="position" :content="index + 1">{{ crumb.name }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
      data: function () {
          return {}
      },
      props: {
          crumbs: Array,
          location: Object
      },
      methods: {
          crumbUrl: function(url) {
              return url.replace(/[-/]/g, " ");
          },
          newSearch: function() {
              window.scrollTo({
                  top: 0,
                  behavior: "smooth"
              });
              document.getElementById("date").click();
          }
      }
  }
</script>

<style scoped lang="scss">
@import 'app/assets/stylesheets/_vars.scss';

ul {
  list-style: none;
  overflow: hidden;
}

li {
  display: inline-block;
  padding: 0 0.625rem 0 0;
  box-sizing: border-box;
}

svg {
  width: .7rem;
  height: .7rem;
  position: relative;
  top: .0625rem;
  fill: $col_orange;
  transform: scale(-1, 1);
}

a, p {
  text-decoration: none;
  font-size: .875rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &.pur {
    color: #424F98;
  }

  &.org {
    color: $col_orange;
    font-weight: 700;
  }
}

</style>