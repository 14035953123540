<template>
  <section id="plist" class="plist">
    <h2 class="title" v-if="!hideTitle">{{ $props.title }}</h2>
    <ul id="list" class="list">
      <propCard
        v-for="property in props"
        :key="property.id"
        :property="property"
        :clName="'prop'"
        :date="$props.date"
        :nights="$props.nights"
        :card="'card seo'"
      />
    </ul>
    <div v-if="hasPagination" class="pagination">
      <pagination
        :pagination="$props.pagination"
        :init="$props.init"
      />
    </div>
    <div v-else class="page">
      <pager
        :page="pageNo"
        :pageCount="pageCount"
      />
    </div>
    <div class="crumb">
      <crumbs
        :crumbs="$props.crumbs"
        :location="location"
      />
    </div>
  </section>
</template>

<script>
import propCard from 'shared/card.vue'
import pager from 'shared/pager.vue'
import pagination from 'shared/pagination.vue'
import crumbs from 'shared/crumbs.vue'

export default {
  data: function () {
    return {
        props: this.$props.render ? this.$props.properties : [],
        initialload: true,
        pageCount: 0,
        location: {
            name: this.$props.title,
            url: this.$props.searchLink,
            loc: this.$props.loc
        },
        hasPagination: false
    }
  },
  props: {
      render: Boolean,
      properties: Array,
      pagination: Object,
      id: Number,
      title: String,
      pageNo: Number,
      page: Object,
      nights: Number,
      date: String,
      config: Object,
      crumbs: Array,
      type: String,
      loc: String,
      hideTitle: Boolean,
      init: String
  },
  components: {
      propCard, pager, pagination, crumbs
  },
  methods: {
      getRequest() {
          return {
              method: "GET",
              credentials: "include",
              headers: {
                  'X-CSRF-TOKEN': st.getCSRFToken()
              }
          };
      },
      replaceUrl(prop, first, second) {
          if (prop && typeof prop.images !== "undefined" && prop.images > 0) {
              prop.images[0].url = prop.images[0].url.replace(first, second);
          }
      },
      transformProps(props, first, second) {
          for (let i = 0; i < props.length; i++) {
              let prop = props[i];
              this.replaceUrl(prop, first, second);
          }
          return props;
      },
      getProperties() {
          let app = this;
          let page = parseInt(app.$props.pageNo);
          let baseUrl = /searches/ + app.id + ".json?include=properties.description,top_reviews" + (page > 1 ? "&page=" + page : '');

          fetch(baseUrl, app.getRequest())
              .then((resp) => resp.json())
              .then(function(data) {
                  if (data.properties.length > 0) {
                      app.$emit("updateParentData", data);
                      app.props = app.transformProps(data.properties, "normal", "smaller");
                      app.pageCount = parseInt(data.pagination.total_pages);
                      app.reviews = data.top_reviews;
                      app.pageNo = parseInt(data.pagination.current_page);
                      window.addEventListener('scroll', app.replaceImages);
                     } else {
                      app.props = [];
                  }
              })
      },
      replaceImages() {
          let app = this;
          let scroll = window.pageYOffset,
              el = app.$el;
          let top = el.getBoundingClientRect().top;

          if (scroll > top) {
              app.props = app.transformProps(app.props, "smaller", "normal");
              window.removeEventListener('scroll', app.replaceImages);
          }
      },
      checkPagination() {
        let app = this;
        let pagination = app.$props.pagination;

        if (typeof pagination === 'object' && pagination !== null) {
          this.hasPagination = true
        }
      }
  },
  mounted: function() {
      let app = this;
      if (!app.render) {
          app.getProperties();
      } else {
          window.addEventListener('scroll', app.replaceImages);
      }
      app.checkPagination();
  }
}
</script>

<style scoped lang="scss">
@import 'app/assets/stylesheets/_vars.scss';

.plist:after {
  content: "";
  display: table;
  clear: both;
}

.pagination {
  width: 100%;
  & + .crumb {
      margin: 0 0 1.25rem;
  }
}

.page {
  margin: 1.25rem 0 0;
}

.list {
  list-style: none;
  -webkit-overflow-scrolling: touch;
  margin: 2rem auto;
  box-sizing: border-box;
  row-gap: 1.25rem;
  -webkit-column-gap: 1.25rem;
  -moz-column-gap: 1.25rem;
  column-gap: 1.25rem;

  .isIE & li {
    width: 100%;
    margin-bottom: 1.25rem;
  }
}

.title {
  margin: 1.25rem 0 0;
}

.page {
  text-align: right;
}

@supports (display: grid) {
  .list {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 100%;
    grid-template-columns: repeat(1, 100%);
  }
}

@media #{$xl-phone} {
  .list {
    -ms-grid-columns: 50%;
    grid-template-columns: repeat(2, calc(50% - .625rem));

    .isIE & li {
      float: left;
      width: calc(50% - 1.25rem);
      margin: 0 .625rem 1.25rem;
    }
  }
}

@media #{$desktop} {
  .title {
    margin: 1.25rem 0 0;
  }

  .page {
    float: right;
    margin: 0;
  }

  .crumb {
    float: left;
  }

  .page, .title {
    width: fit-content;
    display: inline-block;
  }

  .list {
    -ms-grid-columns: 33%;
    grid-template-columns: repeat(3, calc(33% - .625rem));

    .isIE & li {
      width: calc(33% - 1.25rem);
    }
  }
}

</style>